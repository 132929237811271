import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createBlock(_component_v_footer, { app: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        class: "text-center",
        cols: "12"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" © " + _toDisplayString(new Date().getFullYear()) + " - CDM Mariusz Roszkowski ", 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}