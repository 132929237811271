<template>
    <v-footer app>
      <v-col class="text-center" cols="12">
        &copy; {{ new Date().getFullYear() }} - CDM Mariusz Roszkowski
      </v-col>
    </v-footer>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'AppFooter',
  });
  </script>
  
  <style scoped>
  </style>
  