import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css'; // Import MDI Icons
import '@fortawesome/fontawesome-free/css/all.css'; // Import FontAwesome Icons

// Import ikony MDI i FontAwesome z Vuetify
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { fa } from 'vuetify/iconsets/fa';

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi', // Domyślny zestaw ikon (MDI)
    aliases, // Aliasowanie ikon (opcjonalne)
    sets: {
      mdi, // Material Design Icons
      fa,  // FontAwesome Icons
    },
  },
});

createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .mount('#app');
