<template>
  <v-app>
    <AppHeader />
    <v-main>
      <router-view :currencies-data="currenciesData"></router-view>
    </v-main>
    <AppFooter />
  </v-app>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';
import apiClient from './services/apiClient';

export default defineComponent({
  components: {
    AppHeader,
    AppFooter,
  },
  setup() {
    const currenciesData = ref<any[]>([]);

      const fetchCurrencies = async () => {
    try {
      const response = await apiClient.get('/currency-rates');
      const data = response.data;
      
      // Konwertujemy obiekt na tablicę grup
      currenciesData.value = Object.values(data);
    } catch (error) {
      console.error('Błąd pobierania danych:', error);
    }
  };

    // Początkowe pobranie danych
    onMounted(() => {
      fetchCurrencies();
      // Ustawienie interwału na 5 minut
      setInterval(() => {
        fetchCurrencies();
      }, 5 * 60 * 1000); // Co 5 minut
    });

    return {
      currenciesData,
    };
  },
});
</script>

<style>
/* Globalne style */
</style>
