<template>
    <v-container>
      <Header />
      <CurrencyDetails />
      <Footer />
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import Header from '@/components/AppHeader.vue';
  import Footer from '@/components/AppFooter.vue';
  import CurrencyDetails from '@/components/CurrencyDetails.vue';
  
  export default defineComponent({
    name: 'CurrencyDetailsView',
    components: {
      Header,
      Footer,
      CurrencyDetails,
    },
  });
  </script>
  
  <style scoped>
  </style>
  