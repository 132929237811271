<template>
    <v-app-bar app>
      <v-col class="text-center" cols="12">
        <v-toolbar-title>Kursy walut W-wa by CDM</v-toolbar-title>
      </v-col>
    </v-app-bar>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'AppHeader',
  });
  </script>
  
  <style scoped>
  </style>
  