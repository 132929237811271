<template>
  <v-container>
    <v-card class="mx-auto my-5" max-width="1200">
      <v-card-title class="headline text-center">Podsumowanie kursów walut z ostatnich 24 godzin</v-card-title>
      <v-card-text>
        <!-- Przycisk do włączenia powiadomień -->
        <v-btn @click="requestNotificationPermission" color="primary" class="mb-4">
          Włącz powiadomienia
        </v-btn>

        <!-- Opcja debugowania - powiadomienia co 10 sekund -->
        <v-checkbox
          v-model="debugNotifications"
          label="Włącz powiadomienia co 10 sekund (debug)"
          class="mb-4"
        ></v-checkbox>

        <!-- Checkboxy do włączania/wyłączania powiadomień -->
        <v-row class="mb-4">
          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="notifyOnEveryFetch"
              label="Powiadomienia o każdym pobraniu danych"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="notifyOnRateChange"
              label="Powiadomienia tylko o zmianie kursu"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row v-if="currenciesData.length > 0" dense>
          <v-col
            v-for="(group, index) in currenciesData"
            :key="index"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card class="pa-3 my-2" elevation="2">
              <v-card-title class="text-h6 text-primary" v-if="group.length > 0">
                {{ group[group.length - 1].currency }}: <strong>{{ group[group.length - 1].rate }} PLN</strong>
              </v-card-title>
              <v-card-subtitle v-if="group.length > 0">
                <v-icon small class="mr-1">mdi-store</v-icon> 
                <span>Kantor: {{ group[group.length - 1].exchange_point }}</span>
              </v-card-subtitle>
              <v-card-subtitle v-if="group.length > 0">
                <v-icon small class="mr-1">mdi-calendar</v-icon>
                <span>Ostatnia aktualizacja:</span>
                <br>
                <span class="date-text">{{ formattedDate(group[group.length - 1].fetched_at) }}</span>
              </v-card-subtitle>
              <v-card-text v-if="group.length > 0">
                <div class="chart-container">
                  <line-chart :data="prepareChartData(group)" :options="chartOptions(group)"></line-chart>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-alert v-else type="info">Brak danych do wyświetlenia.</v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted, watch } from 'vue';
import { format } from 'date-fns';
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';

// Rejestracja komponentów wykresu
ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

// Definicja interfejsu Currency
interface Currency {
  id: number;
  currency: string;
  rate: number;
  fetched_at: string;
  exchange_point: string;
  created_at: string;
  updated_at: string;
}

export default defineComponent({
  name: 'HomeView',
  components: {
    LineChart: Line,
  },
  props: {
    currenciesData: {
      type: Array as () => Currency[][],
      required: true,
    },
  },
  setup(props) {
    const lastNotifiedRate = ref<number | null>(null); // Przechowywanie ostatniego powiadomionego kursu
    const notifyOnEveryFetch = ref(true); // Powiadomienia o każdym pobraniu danych
    const notifyOnRateChange = ref(false); // Powiadomienia tylko o zmianie kursu
    const debugNotifications = ref(false); // Powiadomienia debugujące co 10 sekund
    let debugInterval: ReturnType<typeof setInterval> | null = null;

    // Funkcja formatowania daty
    const formattedDate = (date: string) => {
      return format(new Date(date), 'dd-MM-yyyy HH:mm');
    };

    // Powiadomienia
    const sendNotification = (message: string) => {
      if (Notification.permission === 'granted') {
        navigator.serviceWorker.getRegistration().then((reg) => {
          if (reg) {
            reg.showNotification('Aktualizacja kursu walut', {
              body: message,
              icon: '/img/icons/icon-192x192.png', // Możesz dodać ikonę dla powiadomienia
              tag: 'currency-update', // Tag, aby unikać zduplikowanych powiadomień
            });
          }
        });
      }
    };

    // Funkcja żądająca uprawnień do powiadomień
    const requestNotificationPermission = () => {
      if (Notification.permission === 'default' || Notification.permission === 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            sendNotification('Powiadomienia zostały włączone!');
          }
        });
      } else if (Notification.permission === 'granted') {
        sendNotification('Powiadomienia są już włączone!');
      }
    };

    // Debugowanie - wywoływanie powiadomień co 10 sekund
    const startDebugNotifications = () => {
      if (!debugInterval) {
        debugInterval = setInterval(() => {
          sendNotification('Debug: Powiadomienie co 10 sekund');
        }, 10000); // 10 sekund
      }
    };

    const stopDebugNotifications = () => {
      if (debugInterval) {
        clearInterval(debugInterval);
        debugInterval = null;
      }
    };

    // Zmiana stanu debugowania
    onMounted(() => {
      if (debugNotifications.value) {
        startDebugNotifications();
      }
    });

    onUnmounted(() => {
      stopDebugNotifications();
    });

    watch(debugNotifications, (newVal: boolean) => {
      if (newVal) {
        startDebugNotifications();
      } else {
        stopDebugNotifications();
      }
    });

    // Przygotowanie danych dla wykresu
    const prepareChartData = (data: Currency[]) => {
      const latestCurrency = data[data.length - 1];

      // Logika powiadomień
      if (notifyOnEveryFetch.value) {
        sendNotification(`${latestCurrency.currency}: ${latestCurrency.rate} PLN`);
      } else if (notifyOnRateChange.value && lastNotifiedRate.value !== latestCurrency.rate) {
        sendNotification(`Zmieniony kurs: ${latestCurrency.currency}: ${latestCurrency.rate} PLN`);
        lastNotifiedRate.value = latestCurrency.rate; // Zapisz ostatni kurs
      }

      return {
        labels: data.map(entry => entry.fetched_at.slice(-8, -3)),
        datasets: [
          {
            label: 'Kurs waluty',
            backgroundColor: '#1976d2',
            borderColor: '#1976d2',
            data: data.map(entry => entry.rate),
          },
        ],
      };
    };

    // Opcje wykresu z zakresem dla osi Y
    const chartOptions = (data: Currency[]) => {
      const minRate = Math.min(...data.map(entry => entry.rate));
      const maxRate = Math.max(...data.map(entry => entry.rate));
      const ratesUpScaling = 0.04 - (maxRate - minRate);
      const fixedMinRate = ratesUpScaling > 0 ? minRate - ratesUpScaling : minRate;
      const fixedMaxRate = ratesUpScaling > 0 ? maxRate + ratesUpScaling : maxRate;

      return {
        pointRadius: 0,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            min: fixedMinRate,
            max: fixedMaxRate,
            ticks: {
              callback: function (value: number) {
                return value.toFixed(2) + ' PLN'; // Formatowanie etykiet
              },
            },
          },
        },
      };
    };

    return {
      formattedDate,
      prepareChartData,
      chartOptions,
      requestNotificationPermission,
      notifyOnEveryFetch,
      notifyOnRateChange,
      debugNotifications,
    };
  },
});
</script>

<style scoped>
.text-primary {
  color: #1976d2 !important;
}
.v-card-title {
  font-weight: bold;
}
.date-text {
  margin-left: 24px;
  font-style: italic;
}
.chart-container {
  height: 400px;  /* Zwiększ wysokość wykresu */
  width: 100%;
}
</style>