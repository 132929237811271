<template>
  <v-container>
    <v-card>
      <v-card-title>{{ currency.name }}</v-card-title>
      <v-card-text>
        <p>Rate: {{ currency.rate }}</p>
        <p>Date: {{ currency.date }}</p>
        <!-- Additional details can go here -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'CurrencyDetails',
  setup() {
    const route = useRoute();
    const currency = ref<any>({});
    
    onMounted(() => {
      const currencyId = route.params.id;
      // Fetch the currency details by ID
      // This could be replaced with an actual API call
      currency.value = {
        id: currencyId,
        name: 'USD',
        rate: '4.50',
        date: '2024-09-03'
      };
      // axios.get(`/api/currency-rates/${this.$route.params.currency}`)
      // .then(response => {
      //   this.currency = response.data;
      // });
    });

    return { currency };
  },
});
</script>

<style scoped>
</style>

