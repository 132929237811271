import axios from "axios";

const apiUrl = process.env.NODE_ENV === 'production' 
  ? 'https://eurowiek.cd-m.pl/be/api' 
  : 'http://localhost:8000/api';

const apiClient = axios.create({
  baseURL: apiUrl,
});

export default apiClient;
